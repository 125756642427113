import React, { FunctionComponent } from "react";
import { Box, Tooltip } from "@mui/material";
import styled from "styled-components";

const Logo = styled.img`
  width: 3rem;
`;

const SkillsBox: FunctionComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "@media (min-width: 200px) and (max-width: 1023px)": {
          justifyContent: "center",
        },
      }}
    >
      <Tooltip title="React" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/react.png")} alt="React" />
        </Box>
      </Tooltip>
      <Tooltip title="Typescript" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/typescript.png")} alt="Typescript" />
        </Box>
      </Tooltip>
      <Tooltip title="Javascript" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/javascript.png")} alt="Javascript" />
        </Box>
      </Tooltip>

      <Tooltip title="Vue3" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/vue.png")} alt="Vue3" />
        </Box>
      </Tooltip>
      <Tooltip title="Python" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/python.png")} alt="Python" />
        </Box>
      </Tooltip>
      <Tooltip title="FastApi" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/fastapi.png")} alt="FastAPI" />
        </Box>
      </Tooltip>
      <Tooltip title="Flask" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/flask.png")} alt="Flask" />
        </Box>
      </Tooltip>
      <Tooltip title="Nodejs" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/nodejs.png")} alt="Nodejs" />
        </Box>
      </Tooltip>
      <Tooltip title="C#" arrow>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "5rem",
            width: "5rem",
            margin: "0 0.5rem 1rem",
            cursor: "help",
            borderRadius: "10px",
            bgcolor: "#222",
            boxShadow: "11px 10px 38px hsla(0, 0%, 0%, 38%)",
            transition: "all 1s ease-in",
            "&:hover": {},
          }}
        >
          <Logo src={require("../../../assets/skills/csharp.png")} alt="C#" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SkillsBox;
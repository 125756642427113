import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Container, Stack, Button, Typography } from "@mui/material";
import VizSensor from "react-visibility-sensor";
import ProfilePic from "../../assets/about/caricature-picture.gif";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const ContainerWrapperClass = {
  display: "flex",
  padding: "5% 0",
  "@media (min-width: 200px) and (max-width: 1023px)": {
    flexDirection: "column",
    alignItems: "center",
  },
};

const HeadingWrapperClass = {
  fontWeight: "bold",
  color: "secondary.main",
};

const RightSide = styled.div`
  flex: 1;
  padding: 10px;
`;

const LeftSide = styled.div`
  flex: 1 1 0%;
  padding: 5%;
  display: flex;
  flex-direction: column;
`;

const About: FunctionComponent = () => {
  let [active, setActive] = useState(false);
  const containerRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (location.pathname !== "/#about") {
            window.history.pushState(null, "", "/#about");
          }
        }
      });
    }, options);

    const currentSectionRef = containerRef.current; 

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, [location]);

  const downloadPDF = () => {
    saveAs(require("../../download/Hina Humayun-Resume.pdf"), "Hina Humayun-Resume.pdf");
  };

  return (
    <VizSensor
      onChange={(isVisible: boolean) => {
        setActive(isVisible);
      }}
    >
      <Container sx={ContainerWrapperClass} ref={containerRef}>
        <RightSide>
          <img
            className="profile-picture"
            alt="profilePicture"
            src={ProfilePic}
          />
        </RightSide>
        <LeftSide>
          <Typography variant="h4" gutterBottom sx={HeadingWrapperClass}>
            Elevator Pitch
          </Typography>
          <p className="about-me">
            Hi! I'm Hina Humayun. I am a 5-year-experienced full-stack software developer with a strong passion for technology, innovation, and pragmatic problem solving and having the chance to work closely with cross functional teams, 
            I have helped implementing complex products  from execution to successful completion. My work experience involves collaborating with diverse teams, including international clients, using the One-Team model and following agile methodologies such as Sprint Planning/Kanban. 
            My ultimate goal is to lead groundbreaking projects and provide top-notch software development services on a global scale.

          </p>

          <Stack spacing={2} direction="row" ref={containerRef}>
            <Button variant="contained" sx ={{bgcolor:"#E8A2A2", color:"#fff"}}  component={Link} to="/#contact">
              Hire Me
            </Button>
            <Button variant="outlined" sx ={{color:"#E8A2A2" ,borderColor: "#E8A2A2" }} onClick={downloadPDF}>
              Download CV
            </Button>
          </Stack>
        </LeftSide>
      </Container>
    </VizSensor>
  );
};

export default About;

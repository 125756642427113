import React, { FunctionComponent } from "react";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import { Typography, Box, Link } from "@mui/material";

const ContactDetails: FunctionComponent = () => {
  return (
    <>
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "secondary.main" }}
        >
         Do you have any projects? Please drop me a message!
        </Typography>
        <p>
        Reach out and tell me how I can assist you. Simply fill out the form, 
        and I'll get back to you as soon as possible.
        </p>
      </Box>

      <Box sx={{ display: "flex", marginTop: "5%" }}>
        <EmailOutlined sx={{ marginRight: "20px" }} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Email:
          </Typography>

          <Link
            href="mailto:info@hinahumayun.com"
            underline="none"
            sx={{
              color: "#000",
              ":hover": {
                color: "primary.main",
              },
            }}
          >
            info@hinahumayun.com
          </Link>
          <Link
            href="mailto:support@hinahumayun.com"
            underline="none"
            sx={{
              color: "#000",
              ":hover": {
                color: "primary.main",
              },
            }}
          >
            support@hinahumayun.com
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default ContactDetails;

import React, { FunctionComponent } from "react";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import { Typography } from "@mui/material";

const ExperienceTimeline: FunctionComponent = () => {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      <TimelineItem>

        <TimelineOppositeContent color="textSecondary">
          2024
        </TimelineOppositeContent> 
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Time shift (Energy Management System)  - TIme shift B.V, Netherlands
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Senior Fullstack Developer
          </Typography>
          <p>
          I led a skilled team in building a modern web application using
React and TypeScript, collaborating closely with stakeholders to
define project requirements. I focused on designing and
implementing the front-end architecture, ensuring robust state
management and reusable UI components. Leveraging tools like
React, Webpack, and Tailwind, I ensured exceptional reliability
and performance. I also spearheaded testing efforts,
implementing unit and end-to-end tests to guarantee code quality.
Additionally, I developed a middleware application using Python
FastAPI, enhancing data security with JWT authorization. Overall,
I increased developer productivity by 50% through code
refactoring and resolved numerous bugs to improve user
experience.

          </p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          2021
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Hummingbird (Fintech) - Strategic Systems International, Pakistan
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Senior Fullstack Developer
          </Typography>
          <p>
          As a full-stack developer for a US-based client's property
management system, I utilized Context API custom hooks for
efficient state management, reducing code defects by 90%. I
implemented Storybook features for streamlined testing and
showcased UI functionality with tailored Material UI components.
Additionally, Implemented serverless lambda queries in AWS. I
mentored a team of four developers, developed RESTful APIs with
Flask, and optimized database interaction with SQLAlchemy. My
efforts resulted in enhanced data security and a 60% reduction in
time and data discrepancies through web scraper
implementation.

          </p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          2020
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Vivian (Healthcare system) - Strategic Systems International, Pakistan
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Full stack developer 
          </Typography>
          <p>
          Played a pivotal role in front-end development for a healthcare
job portal and nurse mobile application, utilizing Vue.js 3, Vuex,
TypeScript, and Tailwind CSS. Consistently achieved high
customer satisfaction rates through effective troubleshooting
and problem-solving.Guided and mentored two junior front-end
developers, fostering their professional growth and contributing
to enhanced team efficiency and productivity. For backend used
nodejs to write authorized rest endpoints with parameterized
queires
          </p>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          2019
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Intelligize (Fintech System) - Northbay Solutions, Pakistan
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "secondary.main" }}
          >
            Software Engineer
          </Typography>
          <p>
          It was a US-based web application with a .NET backend. I created
REST web API endpoints and configured them on Swagger. I
added filters and logging through Sumologic. Additionally, I wrote
complex parsers and LINQ queries and refactored some services
for it.
          </p>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default ExperienceTimeline;

import React, { FunctionComponent, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import Container from "@mui/material/Container";
import profile from "../../assets/heroBanner/profile.png"; 
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useLocation } from "react-router-dom";

const ContainerWrapperClass = {
  height: "100vh",
  "@media (min-width: 200px) and (max-width: 600px)": {
    height: "90vh",
  },
  "@media (min-width: 601px) and (max-width: 1024px)": {
    height: "108vh",
  },
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
`;
const ImageContainer = styled.div`
  width: 25%;
  ${css`
    /* styles for mobile devices */
    @media (max-width: 768px) {
      width: 90%;
    }
  `}
  
  /* Apply rounded corners to the image */
  img {
    border-radius: 50%;
    width: 100%;
    height: auto;
  }
`;

const Typography = styled.h1`
  font-size: 3rem;
  margin-bottom: 0; /* Reset margin */
  text-align: center;

  padding : 0;
  font-family: 'Times New Roman';
  color: #FF0800; /* Adjust color as per your design */

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
`;

const About = styled.p`
  font-family:"serif";
  order: -1;
  text-align: center;
  color: #a55094;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;

  @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 3rem;
    font-weight: bold;
  }
`;

const IconSize = {
  fontSize: "40px",
};




const HeroBanner: FunctionComponent = () => {
  const containerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (location.pathname !== "/#home") {
            window.history.pushState(null, "", "/#home");
          }
        }
      });
    }, options);

    const currentSectionRef = containerRef.current; 

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, [location]);

  return (
    <Container sx={ContainerWrapperClass} ref={containerRef}>
      <ContentWrapper>
      <ImageContainer>
          <img src={profile} alt="Profile" /> 
        </ImageContainer>
        <div style={{display:"block", flexDirection:"column"}}>
          <About>HELLO, MY NAME IS HINA</About>
          <p style={{textAlign: "center" , fontSize: "1.875rem", fontWeight:"bold", fontFamily: "revert"  }}>I build pixel-perfect, user-friendly, and engaging digital experiences.</p>
        </div>
      

        <div >
    <ul style={{  listStyleType: "none", padding: "0",   display: "flex",  flexDirection: "row",  height: "60px",  alignItems: "center", justifyContent: "center",
     gap: "1.5rem", fontSize: "15px"
  }} >
      <li><a href="https://github.com/HinaHumayundev">
      <GitHubIcon  sx={IconSize} />
        </a></li>
        <li><a href="https://www.linkedin.com/in/hina-humayun-dev/">
      <LinkedInIcon  sx={IconSize} />
        </a></li>
        <li><a href="https://www.instagram.com/hinahumayunkh/">
      <InstagramIcon  sx={IconSize} />
        </a></li>
       
    </ul>
  </div>
      </ContentWrapper>
    </Container>
  );
};

export default HeroBanner;

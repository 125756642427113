import React, { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { Box, Link } from "@mui/material";

const FooterWrapper = styled.div`
  box-shadow: rgba(34, 34, 34, 0.1) 0px 0.125rem 0.3125rem;
  background: secondary.main;
  padding: 20px 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  ${css`
    /* styles for mobile devices */
    @media (max-width: 1023px) {
      margin-bottom: 45px;
      flex-direction: column;
      align-items: flex-start;
    }
  `}
`;

const Footer: FunctionComponent = () => {
  return (
    <FooterWrapper>
      <Box textAlign="center">
        © 2024 Proudly Powered by:{" "}
        <Link
          href="https:www.hinahumayun.com"
          underline="none"
          sx={{

            justifyContent: "center",
            fontFamily: "Cursive",
            color: "secondary.main",
            ":hover": {
              color: "primary.main",
            },
          }}
        >
          Hina Humayun
        </Link>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
